import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { debounce } from "lodash";
import { BsThreeDots, BsSearch } from "react-icons/bs";
import CTAButton from "../components/common/CTAButton";
import Spinner from "../components/common/Spinner";
import {
    getAllCategories,
    deleteCategory,
} from "../services/operations/categoryAPI";

const Category = () => {
    const { allCategories, loading } = useSelector((state) => state.category);
    const { token } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [searchTerm, setSearchTerm] = useState("");
    const [filteredCategories, setFilteredCategories] = useState([]);

    useEffect(() => {
        dispatch(getAllCategories());
    }, [dispatch]);

    useEffect(() => {
        const debouncedFilter = debounce(() => {
            const filtered = allCategories.filter((category) =>
                category.name.toLowerCase().includes(searchTerm.toLowerCase())
            );
            setFilteredCategories(filtered);
        }, 300);

        debouncedFilter();
        return () => debouncedFilter.cancel();
    }, [searchTerm, allCategories]);

    const handleEdit = (id) => {
        navigate(`/editCategory/${id}`);
    };

    const handleDelete = (id) => {
        if (window.confirm("Are you sure you want to delete this category?")) {
            dispatch(deleteCategory({ id, token }));
        }
    };

    return (
        <div className="flex flex-col bg-gray-100 min-h-screen">
            <div className="bg-white shadow-md">
                <div className="flex justify-between items-center py-4 px-8 gap-2 flex-wrap">
                    <h1 className="text-2xl font-bold text-gray-800">
                        Categories
                    </h1>
                    <CTAButton
                        link="/createCategory"
                        text="+ Create Category"
                        className=" text-white font-semibold py-2 px-4 rounded"
                    />
                </div>
                <div className="px-8 py-4">
                    <div className="relative">
                        <input
                            type="text"
                            placeholder="Search categories..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            className="w-full px-4 py-2 pl-10 pr-4 rounded-lg border focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                        <BsSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                    </div>
                </div>
            </div>

            {loading ? (
                <div className="w-full h-40 flex justify-center items-center">
                    <Spinner className="!border-black w-10 h-10" />
                </div>
            ) : filteredCategories.length > 0 ? (
                <div className="flex-grow p-8">
                    <div className="bg-white rounded-lg shadow">
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                                <tr>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Name
                                    </th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Color code
                                    </th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Actions
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                                {filteredCategories.map((category) => (
                                    <tr
                                        key={category?._id}
                                        className="hover:bg-gray-50"
                                    >
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            {category?.name}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            <div className="flex items-center">
                                                <div
                                                    className="w-6 h-6 rounded-full mr-2"
                                                    style={{
                                                        backgroundColor:
                                                            category.color,
                                                    }}
                                                ></div>
                                                {category.color}
                                            </div>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            <div className="relative group">
                                                <button className="text-gray-400 hover:text-gray-500">
                                                    <BsThreeDots className="w-5 h-5" />
                                                </button>
                                                <div className="absolute -left-6 w-48 bg-white rounded-md shadow-lg z-10 hidden group-hover:block">
                                                    <button
                                                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                                                        onClick={() =>
                                                            handleEdit(
                                                                category._id
                                                            )
                                                        }
                                                    >
                                                        Edit
                                                    </button>
                                                    <button
                                                        className="block px-4 py-2 text-sm text-red-600 hover:bg-gray-100 w-full text-left"
                                                        onClick={() =>
                                                            handleDelete(
                                                                category._id
                                                            )
                                                        }
                                                    >
                                                        Delete
                                                    </button>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            ) : (
                <div className="flex-grow flex justify-center items-center">
                    <p className="text-gray-500 text-lg">
                        No categories found.
                    </p>
                </div>
            )}
        </div>
    );
};

export default Category;
