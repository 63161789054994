// import { useDispatch, useSelector } from "react-redux";
// import { useNavigate, useParams } from "react-router-dom";
// import {
//     createCategory,
//     getCategoryById,
//     updateCategory,
// } from "../../../services/operations/categoryAPI";
// import { useEffect } from "react";
// import { useForm } from "react-hook-form";
// import Input from "../../common/Input";
// import Button from "../../common/Button";

// const CategoryForm = () => {
//     const { id } = useParams();
//     const { register, handleSubmit, formState, setValue } = useForm();
//     const { errors } = formState;
//     const { loading } = useSelector((state) => state.category);
//     const { token } = useSelector((state) => state.auth);
//     const dispatch = useDispatch();
//     const navigate = useNavigate();

//     const handleCancel = () => {
//         navigate(-1);
//     };

//     useEffect(() => {
//         const fetchCategory = async () => {
//             if (id) {
//                 const category = await dispatch(getCategoryById(id));
//                 setValue("name", category.name);
//                 setValue("color", category.color);
//             }
//         };

//         fetchCategory();
//     }, [id, dispatch, setValue]);

//     const submitForm = (data) => {
//         if (id) {
//             dispatch(
//                 updateCategory({ id, categoryData: data, navigate, token })
//             );
//         } else {
//             dispatch(createCategory({ categoryData: data, navigate, token }));
//         }
//     };

//     return (
//         <div
//             className={`fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black-50 backdrop-blur-md z-50 `}
//         >
//             <form onSubmit={handleSubmit(submitForm)}>
//                 <div className="bg-white w-full md:w-[500px] rounded-lg shadow-lg flex flex-col gap-6">
//                     <div className="bg-[#111024] flex justify-between items-center px-6 py-4 rounded-t-lg">
//                         <h2 className="text-xl text-white font-semibold">
//                             {id ? "Edit Category" : "Create Category"}
//                         </h2>
//                     </div>
//                     <div className="flex gap-3 flex-col px-6">
//                         <Input
//                             star="true"
//                             label="Category Name"
//                             placeholder="Enter name"
//                             name="name"
//                             type="text"
//                             errors={errors?.name}
//                             {...register("name", {
//                                 required: "Category name is required",
//                             })}
//                         />

//                         <Input
//                             star="true"
//                             label="Category color"
//                             placeholder="Enter color code"
//                             name="color"
//                             type="text"
//                             errors={errors?.color}
//                             {...register("color", {
//                                 required: "Category color code is required",
//                             })}
//                         />
//                     </div>

//                     <div className="flex justify-end py-4 px-6 gap-4">
//                         <Button
//                             className="!text-[#201F37] !bg-transparent !hover:bg-transparent border"
//                             onClick={handleCancel}
//                         >
//                             Cancel
//                         </Button>
//                         <Button
//                             type="submit"
//                             className="!bg-[#059669] !hover:bg-[#059700]"
//                             loading={loading}
//                         >
//                             {id ? "Update category" : "Create category"}
//                         </Button>
//                     </div>
//                 </div>
//             </form>
//         </div>
//     );
// };

// export default CategoryForm;




import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import {
    createCategory,
    getCategoryById,
    updateCategory,
} from "../../../services/operations/categoryAPI";
import Input from "../../common/Input";
import Button from "../../common/Button";

const CategoryForm = () => {
    const { id } = useParams();
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm();
    const { loading } = useSelector((state) => state.category);
    const { token } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchCategory = async () => {
            if (id) {
                const category = await dispatch(getCategoryById(id));
                if (category) {
                    setValue("name", category.name);
                    setValue("color", category.color);
                } else {
                    // Handle case where category is not found
                    navigate("/categories");
                }
            }
        };

        fetchCategory();
    }, [id, dispatch, setValue, navigate]);

    const onSubmit = (data) => {
        if (id) {
            dispatch(updateCategory({ id, categoryData: data, navigate, token }));
        } else {
            dispatch(createCategory({ categoryData: data, navigate, token }));
        }
    };

    const handleCancel = () => {
        navigate("/categories");
    };

    return (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center">
            <div className="bg-white w-full max-w-md mx-auto rounded-lg shadow-xl">
                <div className="bg-gray-800 text-white py-4 px-6 rounded-t-lg">
                    <h2 className="text-xl font-semibold">
                        {id ? "Edit Category" : "Create Category"}
                    </h2>
                </div>
                <form onSubmit={handleSubmit(onSubmit)} className="p-6">
                    <div className="space-y-4">
                        <Input
                            label="Category Name"
                            placeholder="Enter category name"
                            {...register("name", {
                                required: "Category name is required",
                                minLength: {
                                    value: 2,
                                    message: "Name must be at least 2 characters",
                                },
                            })}
                            error={errors.name?.message}
                        />
                        <Input
                            label="Color Code"
                            placeholder="Enter color code (e.g., #FF5733)"
                            {...register("color", {
                                required: "Color code is required",
                                pattern: {
                                    value: /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/,
                                    message: "Invalid color code",
                                },
                            })}
                            error={errors.color?.message}
                        />
                        {errors.color?.message && (
                            <p className="text-red-500 text-sm mt-1">
                                {errors.color.message}
                            </p>
                        )}
                    </div>
                    <div className="flex justify-end mt-6 space-x-4">
                        <Button
                            type="button"
                            onClick={handleCancel}
                            className="px-4 py-2 bg-gray-300 text-gray-800 rounded hover:bg-gray-400"
                        >
                            Cancel
                        </Button>
                        <Button
                            type="submit"
                            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                            disabled={loading}
                        >
                            {loading ? "Processing..." : id ? "Update Category" : "Create Category"}
                        </Button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default CategoryForm;
