import React from 'react'
import PageForm from '../components/core/form/PageForm'

const CreatePage = () => {
  return (
    <div className='w-full h-full'>
        <PageForm/>        
    </div>
  )
}

export default CreatePage