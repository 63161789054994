// pages/ContactPage.js
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllContacts } from "../services/operations/contactAPI";
import { debounce } from "lodash";

const Contact = () => {
    const { loading, contacts, contactsPagination } = useSelector((state) => state.contact);
    const { token } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState("");
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");

    useEffect(() => {
        const debounced = debounce(() => {
            dispatch(
                getAllContacts({
                    data: {
                        search: debouncedSearchTerm,
                        page: currentPage,
                        limit: contactsPagination.limit,
                    },
                    token,
                })
            );
        }, 500);

        debounced();

        return () => {
            debounced.cancel();
        };
    }, [dispatch, currentPage, token, debouncedSearchTerm, contactsPagination.limit]);

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
        setDebouncedSearchTerm(e.target.value);
        setCurrentPage(1);
    };

    return (
        <div className="w-full min-h-screen flex flex-col gap-6 p-6 bg-gray-100">
            <div className="bg-white shadow rounded-lg px-6 py-4">
                <h1 className="text-3xl font-bold text-gray-800 mb-4">Contacts</h1>
                <div className="flex flex-wrap gap-4 mb-4">
                    <input
                        type="text"
                        placeholder="Search contacts..."
                        value={searchTerm}
                        onChange={handleSearchChange}
                        className="flex-grow px-4 py-2 border rounded-md"
                    />
                </div>
            </div>
            {loading ? (
                <div className="text-center">Loading...</div>
            ) : contacts.length > 0 ? (
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                    {contacts.map((contact) => (
                        <div key={contact._id} className="bg-white shadow rounded-lg p-4">
                            <h2 className="text-xl font-bold mb-2">{contact.name}</h2>
                            <p className="mb-2">{contact.email}</p>
                            <p>{contact.message}</p>
                        </div>
                    ))}
                </div>
            ) : (
                <p className="text-center text-gray-500 mt-8">No records found.</p>
            )}
            {!loading && contactsPagination && contacts.length > 0 && (
                <div className="flex justify-center items-center mt-8">
                    <button
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                        className="px-4 py-2 bg-blue-500 text-white rounded-l-md disabled:bg-gray-300 hover:bg-blue-600 transition-colors"
                    >
                        Previous
                    </button>
                    <span className="px-4 py-2 bg-white border-t border-b">
                        Page {currentPage} of {contactsPagination.totalPages}
                    </span>
                    <button
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage === contactsPagination.totalPages}
                        className="px-4 py-2 bg-blue-500 text-white rounded-r-md disabled:bg-gray-300 hover:bg-blue-600 transition-colors"
                    >
                        Next
                    </button>
                </div>
            )}
        </div>
    );
};

export default Contact;
