import axios from "axios";
import { categoryEndPoints } from "../apis";
import { setAllCategories, setLoading } from "../../slices/categorySlice";
import toast from "react-hot-toast";

const {
    CREATE_CATEGORY_API,
    DELETE_CATEGORY_API,
    GET_ALL_CATEGORIES_API,
    GET_CATEGORY_BY_ID_API,
    UPDATE_CATEGORY_API,
} = categoryEndPoints;

export const getAllCategories = () => async (dispatch) => {
    try {
        dispatch(setLoading(true));
        const response = await axios.get(GET_ALL_CATEGORIES_API);
        dispatch(setAllCategories(response.data.data));
        dispatch(setLoading(false));
    } catch (error) {
        console.error("Error fetching pages:", error);
        dispatch(setLoading(false));
    }
};

export const getCategoryById = (id) => async (dispatch) => {
    try {
        dispatch(setLoading(true));
        const response = await axios.get(`${GET_CATEGORY_BY_ID_API}/${id}`);
        dispatch(setLoading(false));
        return response.data.data;
    } catch (error) {
        console.error("Error fetching category:", error);
        dispatch(setLoading(false));
        toast.error(error.response.data.message);
    }
};

export const createCategory =
    ({categoryData, navigate, token}) => async (dispatch) => {
        try {
            dispatch(setLoading(true));
            const response = await axios.post(
                CREATE_CATEGORY_API,
                categoryData,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            // dispatch(getAllCategories());
            toast.success(response.data.message);
            dispatch(setLoading(false));
            navigate("/categories");
        } catch (error) {
            console.error("Error creating category:", error);
            dispatch(setLoading(false));
            toast.error(error.response.data.message);
        }
    };

export const updateCategory = ({id, categoryData, navigate, token})=> async (dispatch)=>{
    try {
        dispatch(setLoading(true));
        const response = await axios.put(
            `${UPDATE_CATEGORY_API}/${id}`,
            categoryData,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        dispatch(getAllCategories());
        toast.success(response.data.message);
        dispatch(setLoading(false));
        navigate("/categories");
    } catch (error) {
        console.error("Error updating category:", error);
        dispatch(setLoading(false));
        toast.error(error.response.data.message);
    }
}

export const deleteCategory = ({id, token})=> async (dispatch)=>{
    try {
        dispatch(setLoading(true));
        const response = await axios.delete(
            `${DELETE_CATEGORY_API}/${id}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        dispatch(getAllCategories());
        toast.success(response.data.message);
        dispatch(setLoading(false));
    } catch (error) {
        console.error("Error deleting category:", error);
        dispatch(setLoading(false));
        toast.error(error.response.data.message);
    }
}