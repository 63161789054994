import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deletePage, getAllPages } from "../services/operations/pageAPI";
import { getAllCategories } from "../services/operations/categoryAPI";
import CTAButton from "../components/common/CTAButton";
import Input from "../components/common/Input";
import Spinner from "../components/common/Spinner";
import { formatDate } from "../utils/formatDate";
import { BsThreeDots } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import { debounce } from "lodash";

const AllPages = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useSelector((state) => state.auth);
  const { allPages, loading, allPagesPagination } = useSelector((state) => state.page);
  const { allCategories } = useSelector((state) => state.category);

  const [searchTerm, setSearchTerm] = useState('');
  const [filters, setFilters] = useState({
    status: "",
    category: "",
    isFeatured: "",
    limit: allPagesPagination.limit,
    page: 1
  });
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');

  useEffect(() => {
    dispatch(getAllCategories());
  }, [dispatch]);

  useEffect(() => {
    const debounced = debounce(() => {
      dispatch(getAllPages({ data: { search: debouncedSearchTerm, ...filters }, token }));
    }, 500);

    debounced();

    return () => {
      debounced.cancel();
    };
  }, [debouncedSearchTerm, filters, dispatch, token]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setDebouncedSearchTerm(event.target.value);
  };

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
      page: 1 // Reset to first page when changing filters
    }));
  };

  const handlePageChange = (newPage) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      page: newPage
    }));
  };

  const handleEdit = (id) => {
    navigate(`/editPage/${id}`);
  };

  const handleDelete = async (id) => {
    await dispatch(deletePage(id, token));
    dispatch(getAllPages({ data: filters, token }));
  };

  return (
    <div className="w-full h-full bg-gray-100 p-6">
      <div className="bg-white rounded-lg shadow-md p-6">
        {/* Header Section */}
        <div className="flex justify-between items-center mb-6 pb-4 border-b">
          <div>
            <h1 className="text-2xl font-bold text-gray-800">Pages</h1>
            <p className="text-gray-600">Create and publish pages.</p>
          </div>
          <CTAButton
            link="/createPage"
            text="+ Add Page"
            className="text-white font-semibold py-2 px-4 rounded"
          />
        </div>

        {/* Search and Filters */}
        <div className="flex flex-wrap gap-4 mb-6">
          <Input
            type="text"
            placeholder="Search pages"
            value={searchTerm}
            onChange={handleSearchChange}
            className="flex-grow"
          />
          <select
            name="status"
            onChange={handleFilterChange}
            value={filters.status}
            className="p-2 border rounded"
          >
            <option value="">All Statuses</option>
            <option value="draft">Draft</option>
            <option value="published">Published</option>
          </select>
          <select
            name="category"
            onChange={handleFilterChange}
            value={filters.category}
            className="p-2 border rounded"
          >
            <option value="">All Categories</option>
            {allCategories.map((category) => (
              <option key={category._id} value={category.name}>{category.name}</option>
            ))}
          </select>
          <select
            name="isFeatured"
            onChange={handleFilterChange}
            value={filters.isFeatured}
            className="p-2 border rounded"
          >
            <option value="">All</option>
            <option value="true">Featured</option>
            <option value="false">Not Featured</option>
          </select>
          <select
            name="limit"
            onChange={handleFilterChange}
            value={filters.limit}
            className="p-2 border rounded"
          >
            <option value="10">10 per page</option>
            <option value="25">25 per page</option>
            <option value="50">50 per page</option>
          </select>
        </div>

        {/* Table Section */}
        {loading ? (
          <div className="w-full h-40 flex justify-center items-center">
            <Spinner className="border-black w-10 h-10" />
          </div>
        ) : allPages.length ? (
          <>
            <div className="overflow-x-auto pb-16">
              <table className="min-w-full bg-white">
                <thead className="bg-gray-100">
                  <tr>
                    <th className="py-3 px-4 text-left">Title</th>
                    <th className="py-3 px-4 text-left">URL slug</th>
                    <th className="py-3 px-4 text-left">Created By</th>
                    <th className="py-3 px-4 text-left">Created At</th>
                    <th className="py-3 px-4 text-left">Read</th>
                    <th className="py-3 px-4 text-left">Featured</th>
                    <th className="py-3 px-4 text-left">Status</th>
                    <th className="py-3 px-4 text-left">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {allPages.map((page) => (
                    <tr key={page?._id} className="border-b hover:bg-gray-50">
                      <td className="py-3 px-4 cursor-pointer hover:underline"><Link to={`/pages/${page?.slug}`}>{page?.title}</Link></td>
                      <td className="py-3 px-4">{decodeURIComponent(page?.slug)}</td>
                      <td className="py-3 px-4">{page?.createdBy}</td>
                      <td className="py-3 px-4">{formatDate(page?.createdAt)}</td>
                      <td className="py-3 px-4">{page?.read} min</td>
                      <td className="py-3 px-4">{page?.isFeatured ? "Yes" : "No"}</td>
                      <td className="py-3 px-4">
                        <span className={`px-2 py-1 rounded-full text-xs capitalize ${
                          page?.status === "draft" ? "bg-orange-100 text-yellow-800" : "bg-green-100 text-green-800"
                        }`}>
                          {page?.status}
                        </span>
                      </td>
                      <td className="py-3 px-4">
                        <div className="relative group">
                          <button className="p-1 hover:bg-gray-100 rounded">
                            <BsThreeDots className="w-5 h-5" />
                          </button>
                          <div className="absolute right-0 mt-0 w-48 bg-white rounded-md shadow-lg z-10 hidden group-hover:block">
                            <button
                              onClick={() => handleEdit(page._id)}
                              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                            >
                              Edit
                            </button>
                            <button
                              onClick={() => handleDelete(page._id)}
                              className="block px-4 py-2 text-sm text-red-600 hover:bg-gray-100 w-full text-left"
                            >
                              Delete
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {/* Pagination */}
            <div className="mt-6 flex justify-center">
              <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
                <button
                  onClick={() => handlePageChange(filters.page - 1)}
                  disabled={filters.page === 1}
                  className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                >
                  Previous
                </button>
                <span className="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700">
                  Page {filters.page} of {allPagesPagination.totalPages}
                </span>
                <button
                  onClick={() => handlePageChange(filters.page + 1)}
                  disabled={filters.page === allPagesPagination.totalPages}
                  className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                >
                  Next
                </button>
              </nav>
            </div>
          </>
        ) : (
          <p className="text-center text-gray-500">No records found.</p>
        )}
      </div>
    </div>
  );
};

export default AllPages;