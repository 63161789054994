// import React from "react";

// const PageCardSkeleton = () => {
//     return (
//         <div className="animate-pulse border max-sm:w-full sm:w-[45%] md:w-[32%] flex flex-col">
//             <div className="relative">
//                 <div className="w-full h-60 bg-gray-300"></div>
//                 <div className="absolute p-2 bg-gray-400 top-4 left-4 w-16 h-6"></div>
//             </div>
//             <div className="flex flex-col p-4 gap-3 flex-1">
//                 <div className="flex flex-col gap-2 mb-auto">
//                     <div className="w-full h-6 bg-gray-300"></div>
//                     <div className="w-full h-4 bg-gray-300"></div>
//                     <div className="w-full h-4 bg-gray-300"></div>
//                 </div>
//                 <div className="h-[1px] border"></div>
//                 <div className="flex justify-between items-center py-2">
//                     <div className="w-24 h-4 bg-gray-300"></div>
//                     <div className="flex gap-2">
//                         <div className="w-16 h-4 bg-gray-300"></div>
//                         <div className="w-16 h-4 bg-gray-300"></div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default PageCardSkeleton;

import React from "react";

const PageCardSkeleton = () => {
  return (
    <div className="bg-white rounded-lg shadow-md overflow-hidden animate-pulse">
      <div className="relative">
        <div className="w-full h-48 bg-gray-300"></div>
        <div className="absolute top-4 left-4 w-16 h-6 bg-gray-400 rounded"></div>
      </div>
      <div className="p-4 flex flex-col h-64">
        <div className="w-3/4 h-6 bg-gray-300 mb-2"></div>
        <div className="w-full h-4 bg-gray-300 mb-2"></div>
        <div className="w-2/3 h-4 bg-gray-300"></div>
        <div className="border-t mt-auto pt-4">
          <div className="flex justify-between items-center">
            <div className="w-1/3 h-4 bg-gray-300"></div>
            <div className="flex gap-2">
              <div className="w-16 h-4 bg-gray-300"></div>
              <div className="w-16 h-4 bg-gray-300"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageCardSkeleton;