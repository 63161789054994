// services/operations/contactAPI.js
import axios from "axios";
import { GET_ALL_CONTACTS_API } from "../apis";
import toast from "react-hot-toast";
import { setContacts, setContactsPagination, setLoading } from "../../slices/contactSlice";


export const getAllContacts = ({ data, token }) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await axios.get(GET_ALL_CONTACTS_API, {
      params: data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.data.success) {
      throw new Error(response.data.message);
    }

    dispatch(setContacts(response.data.data.contacts));
    dispatch(setContactsPagination(response.data.data.pagination));
  } catch (error) {
    console.error("Error fetching contacts:", error);
    toast.error(error.response?.data?.message || "An error occurred while fetching contacts");
  } finally {
    dispatch(setLoading(false));
  }
};
