import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPublishedPages } from "../services/operations/pageAPI";
import { getAllCategories } from "../services/operations/categoryAPI";
import DashboardPageCard from "../components/common/DashboardPageCard";
import PageCardSkeleton from "../components/skeleton/PageCardSkeleton";
import { debounce } from "lodash";

const Dashboard = () => {
    const { loading, publishedPages, publishedPagesPagination } = useSelector((state) => state.page);
    const { token } = useSelector((state) => state.auth);
    const { allCategories } = useSelector((state) => state.category);
    const dispatch = useDispatch();
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState("");
    const [filters, setFilters] = useState({
        category: "",
        isFeatured: "",
        limit: publishedPagesPagination.limit || 9,
    });
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");

    useEffect(() => {
        dispatch(getAllCategories());
    }, [dispatch]);

    useEffect(() => {
        const debounced = debounce(() => {
            dispatch(
                getPublishedPages({
                    data: { 
                        status: "published", 
                        page: currentPage, 
                        limit: filters.limit,
                        search: debouncedSearchTerm,
                        ...filters
                    },
                    token,
                })
            );
        }, 500);

        debounced();

        return () => {
            debounced.cancel();
        };
    }, [dispatch, currentPage, token, debouncedSearchTerm, filters]);

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
        setDebouncedSearchTerm(e.target.value);
        setCurrentPage(1);
    };

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters(prevFilters => ({
            ...prevFilters,
            [name]: value
        }));
        setCurrentPage(1);
    };

    return (
        <div className="w-full min-h-screen flex flex-col gap-6 p-6 bg-gray-100">
            <div className="bg-white shadow rounded-lg px-6 py-4">
                <h1 className="text-3xl font-bold text-gray-800 mb-4">Published Pages</h1>
                <div className="flex flex-wrap gap-4 mb-4">
                    <input
                        type="text"
                        placeholder="Search pages..."
                        value={searchTerm}
                        onChange={handleSearchChange}
                        className="flex-grow px-4 py-2 border rounded-md"
                    />
                    <select
                        name="category"
                        value={filters.category}
                        onChange={handleFilterChange}
                        className="px-4 py-2 border rounded-md"
                    >
                        <option value="">All Categories</option>
                        {allCategories.map((category) => (
                            <option key={category._id} value={category.name}>{category.name}</option>
                        ))}
                    </select>
                    <select
                        name="isFeatured"
                        value={filters.isFeatured}
                        onChange={handleFilterChange}
                        className="px-4 py-2 border rounded-md"
                    >
                        <option value="">All Pages</option>
                        <option value="true">Featured</option>
                        <option value="false">Not Featured</option>
                    </select>
                    <select
                        name="limit"
                        value={filters.limit}
                        onChange={handleFilterChange}
                        className="px-4 py-2 border rounded-md"
                    >
                        <option value="9">9 per page</option>
                        <option value="18">18 per page</option>
                        <option value="27">27 per page</option>
                    </select>
                </div>
            </div>
            {loading ? (
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                    {[...Array(9)].map((_, index) => <PageCardSkeleton key={index} />)}
                </div>
            ) : publishedPages.length > 0 ? (
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                    {publishedPages.map((page) => <DashboardPageCard page={page} key={page._id} />)}
                </div>
            ) : (
                <p className="text-center text-gray-500 mt-8">No records found.</p>
            )}
            {!loading && publishedPagesPagination && publishedPages.length > 0 && (
                <div className="flex justify-center items-center mt-8">
                    <button
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                        className="px-4 py-2 bg-blue-500 text-white rounded-l-md disabled:bg-gray-300 hover:bg-blue-600 transition-colors"
                    >
                        Previous
                    </button>
                    <span className="px-4 py-2 bg-white border-t border-b">
                        Page {currentPage} of {publishedPagesPagination.totalPages}
                    </span>
                    <button
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage === publishedPagesPagination.totalPages}
                        className="px-4 py-2 bg-blue-500 text-white rounded-r-md disabled:bg-gray-300 hover:bg-blue-600 transition-colors"
                    >
                        Next
                    </button>
                </div>
            )}
        </div>
    );
};

export default Dashboard;