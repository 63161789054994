import { combineReducers } from "@reduxjs/toolkit";
import authReducer from "../slices/authSlice";
import pageReducer from "../slices/pageSlice";
import categoryReducer from "../slices/categorySlice"
import contactReducer from "../slices/contactSlice"


const rootReducer= combineReducers({
    auth: authReducer,
    page: pageReducer,
    category: categoryReducer,
    contact: contactReducer,
})

export default rootReducer;