import axios from "axios";
import { pageEndPoints } from "../apis";
import {
    setAllPages,
    setAllPagesPagination,
    setLoading,
    setPublishedPages,
    setPublishedPagesPagination,
} from "../../slices/pageSlice";
import toast from "react-hot-toast";

const {
    CREATE_PAGE_API,
    UPDATE_PAGE_API,
    DELETE_PAGE_API,
    GET_PAGE_API,
    GET_ALL_PAGES_API,
} = pageEndPoints;

export const getAllPages = ({data,token}) => async (dispatch) => {
    try {
        dispatch(setLoading(true));
        const response = await axios.get(GET_ALL_PAGES_API, { params: data , headers:{
            Authorization: `Bearer ${token}`
        }});

        if(!response.data.success){
            throw new Error(response.data.message);
        }
        dispatch(setAllPages(response.data.data.pages));
        dispatch(setAllPagesPagination(response.data.data.pagination));
    } catch (error) {
        console.error("Error fetching pages:", error);
        toast.error(error.response.data.message);
    } finally {
        dispatch(setLoading(false));
    }
};

export const getPublishedPages = ({ data, token }) => async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await axios.get(GET_ALL_PAGES_API, {
        params: data,
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
  
      if (!response.data.success) {
        throw new Error(response.data.message);
      }
  
      dispatch(setPublishedPages(response.data.data.pages));
      dispatch(setPublishedPagesPagination(response.data.data.pagination));
    } catch (error) {
      console.error("Error fetching pages:", error);
      toast.error(error.response?.data?.message || "An error occurred while fetching pages");
    } finally {
      dispatch(setLoading(false));
    }
  };

export const getPageByIdOrSlug = (id) => async (dispatch) => {
    try {
        dispatch(setLoading(true));
        const response = await axios.get(`${GET_PAGE_API}/${id}`);
        if (!response.data.success) {
            throw new Error(response.data.message);
        }
        await dispatch(setLoading(false));
        return response.data.data;
    } catch (error) {
        dispatch(setLoading(false));
        toast.error(error.response.data.message);
    }
};

export const createPage = (pageData, navigate, token) => async (dispatch) => {
    try {
        dispatch(setLoading(true));
        const response = await axios.post(CREATE_PAGE_API, pageData, {
            headers: {
                Authorization: "Bearer " + token,
                'Access-Control-Allow-Origin': 'https://admin.tipconcepts.in',
            },
        });

        if (!response.data.success) {
            throw new Error(response.data.message);
        }

        toast.success(response.data.message);
        dispatch(setLoading(false));
        navigate("/pages");
    } catch (error) {
        // console.log(error);
        dispatch(setLoading(false));
        toast.error(error.data.message);
    }
};

export const updatePage =
    (id, pageData, navigate, token) => async (dispatch) => {
        try {
            dispatch(setLoading(true));

            const response = await axios.put(
                `${UPDATE_PAGE_API}/${id}`,
                pageData,
                {
                    headers: {
                        Authorization: "Bearer " + token,
                    },
                }
            );

            if (!response.data.success) {
                throw new Error(response.data.message);
            }
            toast.success(response.data.message);
            dispatch(setLoading(false));
            navigate("/pages");
        } catch (error) {
            // console.log(error);
            dispatch(setLoading(false));
            toast.error(error.data.message);
        }
    };

export const deletePage = (id, token) => async (dispatch) => {
    try {
        dispatch(setLoading(true));
        const response = await axios.delete(`${DELETE_PAGE_API}/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        toast.success(response.data.message);
        dispatch(setLoading(false));
        dispatch(getAllPages({token}));
    } catch (error) {
        dispatch(setLoading(false));
        toast.error(error.response.data.message);
    }
};
