import React from "react";
import { Link } from "react-router-dom";

const DashboardPageCard = ({ page }) => {
    return (
        <Link to={`/pages/${page?.slug}`} className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-all duration-300 flex flex-col h-full">
            <div className="relative">
                <img
                    src={page.thumbnail}
                    alt={page.title}
                    className="w-full h-48 object-cover"
                    loading="lazy"
                />
                <div className="absolute top-4 left-4 px-3 py-1 bg-blue-500 text-white text-sm font-semibold rounded-full">
                    {page.category}
                </div>
            </div>
            <div className="p-5 flex flex-col flex-grow">
                <h2 className="text-xl font-bold text-gray-800 mb-2 line-clamp-2">{page.title}</h2>
                <p className="text-gray-600 mb-4 flex-grow line-clamp-3 text-sm">{page.subtitle}</p>
                <div className="border-t pt-4 mt-auto">
                    <div className="flex justify-between items-center text-sm text-gray-500">
                        <p className="font-medium">{page.createdBy}</p>
                        <div className="flex items-center">
                            <span className="mr-2">{new Date(page.createdAt).toLocaleDateString()}</span>
                            <span className="bg-gray-200 px-2 py-1 rounded-full text-xs">{page.read} min read</span>
                        </div>
                    </div>
                </div>
            </div>
        </Link>
    );
};

export default DashboardPageCard;