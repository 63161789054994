
import { createSlice } from "@reduxjs/toolkit"

const initialState ={
    loading:false,
    allPages:[],
    allPagesPagination:{
        currentPage:1,
        totalPages:1,
        limit:25,
    },
    publishedPages:[],
    publishedPagesPagination:{
        currentPage:1,
        totalPages:1,
        limit:9,
    }
}

const pageSlice= createSlice({
    name: "page",
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        setAllPages: (state, action) => {
            state.allPages = action.payload
        },
        setPublishedPages: (state, action) => {
            state.publishedPages = action.payload
        },
        setAllPagesPagination: (state, action) => {
            state.allPagesPagination = action.payload
        },
        setPublishedPagesPagination: (state, action) => {
            state.publishedPagesPagination = action.payload
        },
    },
})

export const {setLoading, setAllPages, setPublishedPages, setAllPagesPagination, setPublishedPagesPagination}= pageSlice.actions;

export default pageSlice.reducer;
