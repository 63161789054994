import { useState, useEffect } from "react";
import { BsThreeDots } from "react-icons/bs";
import Button from "../../common/Button";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import Input from "../../common/Input";
import { useDispatch, useSelector } from "react-redux";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
    createPage,
    deletePage,
    getPageByIdOrSlug,
    updatePage,
} from "../../../services/operations/pageAPI";
import { getAllCategories } from "../../../services/operations/categoryAPI";

const toolbarOptions = [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
    ],
    ["link", "image", "video"],
    ["clean"],
];

// const PageForm = () => {
//     const { register, handleSubmit, formState, setValue, getValues } =
//         useForm();
//     const { errors } = formState;
//     const navigate = useNavigate();
//     const { token } = useSelector((state) => state.auth);
//     const { loading, allCategories } = useSelector((state) => state.category);
//     const [currentPage, setCurrentPage] = useState(null);
//     const dispatch = useDispatch();
//     const params = useParams();

//     const [mediaLinks, setMediaLinks] = useState([]);

//     useEffect(() => {
//         const fetchData = async () => {
//             await dispatch(getAllCategories());
//             if (params.id) {
//                 const data = await dispatch(getPageByIdOrSlug(params.id));
//                 setCurrentPage(data);
//             }
//         };
//         if (!currentPage) {
//             fetchData();
//         }

//         if (currentPage) {
//             setValue("title", currentPage.title);
//             setValue("subtitle", currentPage.subtitle);
//             setValue("thumbnail", currentPage.thumbnail);
//             setValue("iframe", currentPage.iframe);
//             setValue("description", currentPage.description);
//             setValue("slug", currentPage.slug);
//             setValue("status", currentPage.status);
//             setValue("category", currentPage.category);
//             setValue("createdBy", currentPage.createdBy);
//             setValue("content", currentPage.content);
//             setValue("read", currentPage.read);
//             setValue("metaTags", currentPage.metaTags);
//             setValue("isFeatured", currentPage.isFeatured);
//             setMediaLinks(currentPage.mediaLinks.media || []);
//         }
//     }, [params.id, dispatch, currentPage, setValue]);

//     const handleCancel = () => {
//         navigate(-1);
//     };

//     const submit = async (data) => {
//         data["mediaLinks"].media = mediaLinks;

//         if (params.id) {
//             await dispatch(updatePage(currentPage._id, data, navigate, token));
//         } else {
//             await dispatch(createPage(data, navigate, token));
//         }
//     };

//     const handleEditorChange = (description) => {
//         setValue("description", description);
//     };

//     const deletePageHandler = async () => {
//         await dispatch(deletePage(currentPage._id, token));
//         navigate(-1);
//     };

//     const handlePreview = () => {
//         navigate("/pages/" + currentPage.slug);
//     };

//     const handleAddMediaLink = () => {
//         setMediaLinks([...mediaLinks, ""]);
//     };

//     const handleRemoveMediaLink = (index) => {
//         setMediaLinks(mediaLinks.filter((_, i) => i !== index));
//     };

//     const handleMediaLinkChange = (index, value) => {
//         const updatedMediaLinks = mediaLinks.map((link, i) =>
//             i === index ? value : link
//         );
//         setMediaLinks(updatedMediaLinks);
//     };

//     return (
//         <div className="w-full h-full">
//             <form onSubmit={handleSubmit(submit)}>
//                 {/* top section  */}
//                 <div className="flex justify-between py-6 px-8 gap-2 flex-wrap border-b">
//                     <div className="flex items-center gap-2 max-md:ml-7">
//                         <h1 className="heading1">
//                             {params.id ? "Edit Page" : "Create Page"}
//                         </h1>
//                     </div>
//                     <div className="flex gap-4">
//                         {params.id && (
//                             <div className="border rounded-md p-2 cursor-pointer group relative">
//                                 <BsThreeDots className="w-6 h-6" />
//                                 <div className="border rounded bg-white absolute top-[40px] right-2 invisible group-hover:visible">
//                                     <p
//                                         className="paragraph border-b py-2 pl-3 pr-4 hover:bg-[#EEF2FF] cursor-pointer"
//                                         onClick={handlePreview}
//                                     >
//                                         Preview
//                                     </p>
//                                     <p
//                                         className="paragraph !text-[#DC2626] py-2 pl-3 pr-4 hover:bg-[#EEF2FF] cursor-pointer"
//                                         onClick={deletePageHandler}
//                                     >
//                                         Delete
//                                     </p>
//                                 </div>
//                             </div>
//                         )}

//                         <Button
//                             className="bg-transparent hover:bg-transparent border !text-[#201F37]"
//                             onClick={handleCancel}
//                         >
//                             Cancel
//                         </Button>
//                         <Button type="submit" loading={loading}>
//                             {params.id ? "Update" : "Save"}
//                         </Button>
//                     </div>
//                 </div>

//                 {/* form section  */}
//                 <div className="w-full h-full flex flex-wrap">
//                     <div className="w-full md:w-[70%] md:border-r max-md:border-b p-6 flex flex-col gap-4">
//                         <Input
//                             star="true"
//                             label="Title"
//                             placeholder="Enter title"
//                             name="title"
//                             type="text"
//                             errors={errors?.title}
//                             {...register("title", {
//                                 required: "Title is required",
//                             })}
//                         />

//                         <Input
//                             star="true"
//                             label="Sub Title"
//                             placeholder="Enter sub title"
//                             name="subtitle"
//                             type="text"
//                             errors={errors?.subtitle}
//                             {...register("subtitle", {
//                                 required: "Sub title is required",
//                             })}
//                         />

//                         <Input
//                             star="true"
//                             label="Thumbnail URL"
//                             placeholder="Enter thumbnail URL"
//                             name="thumbnail"
//                             type="text"
//                             errors={errors?.thumbnail}
//                             {...register("thumbnail", {
//                                 required: "Thumbnail URL is required",
//                             })}
//                         />

//                         <Input
//                             star="true"
//                             label="Youtube Iframe"
//                             placeholder="Enter youtube iframe code"
//                             name="iframe"
//                             type="text"
//                             errors={errors?.iframe}
//                             {...register("iframe", {
//                                 required: "Youtube iframe code is required",
//                             })}
//                         />

//                         <div>
//                             <label htmlFor="content" className="paragraph">
//                                 Body <sup className="text-[#DA0128]">*</sup>
//                             </label>
//                             <ReactQuill
//                                 theme="snow"
//                                 name="description"
//                                 modules={{ toolbar: toolbarOptions }}
//                                 value={getValues("description")}
//                                 className="react-quill"
//                                 onChange={handleEditorChange}
//                             />
//                             {errors?.description && (
//                                 <span className="text-xs text-[#DA0128]">
//                                     {errors?.description.message}
//                                 </span>
//                             )}
//                         </div>

//                         {/* Multiple media links */}
//                         <div className="flex flex-col gap-2">
//                             <label className="paragraph md:pl-1 paragraph">
//                                 Media Links
//                             </label>
//                             {mediaLinks.map((link, index) => (
//                                 <div key={index} className="flex gap-2 mb-2">
//                                     <input
//                                         type="text"
//                                         value={link}
//                                         onChange={(e) =>
//                                             handleMediaLinkChange(
//                                                 index,
//                                                 e.target.value
//                                             )
//                                         }
//                                         className="border p-2 rounded-lg  w-full hover:border-[#838894] outline-none"
//                                     />
//                                     <Button
//                                         className="bg-red-500 hover:bg-red-600 text-white max-w-max"
//                                         onClick={() =>
//                                             handleRemoveMediaLink(index)
//                                         }
//                                     >
//                                         Remove
//                                     </Button>
//                                 </div>
//                             ))}
//                             <Button
//                                 className="bg-green-500 hover:bg-green-600 text-white max-w-max"
//                                 onClick={handleAddMediaLink}
//                             >
//                                 Add Media Link
//                             </Button>
//                         </div>
//                     </div>
        //             <div className="w-full md:w-[30%] flex flex-col gap-3 px-3 pb-6">
        //                 <div className="py-3.5 px-4 border-b">
        //                     <h1 className="heading1">Configurations</h1>
        //                 </div>
        //                 <Input
        //                     star="true"
        //                     label="URL Slug"
        //                     placeholder="homepage"
        //                     name="slug"
        //                     type="text"
        //                     errors={errors?.slug}
        //                     {...register("slug", {
        //                         required: "URL slug is required",
        //                     })}
        //                 />
        //                 <div>
        //                     <label className="paragraph">
        //                         Status <sup className="text-[#DA0128]">*</sup>
        //                     </label>
        //                     <select
        //                         {...register("status", {
        //                             required: "Status is required",
        //                         })}
        //                         className="border p-2 rounded-lg  bg-white w-full hover:border-[#838894] outline-none"
        //                     >
        //                         <option value="">Select status</option>
        //                         <option value="draft">Draft</option>
        //                         <option value="published">Published</option>
        //                     </select>   
        //                     {errors?.status && (
        //                         <span className="text-xs text-[#DA0128]">
        //                             {errors?.status.message}
        //                         </span>
        //                     )}
        //                 </div>
        //                 <div>
        //                     <label className="paragraph">
        //                         Category <sup className="text-[#DA0128]">*</sup>
        //                     </label>
        //                     <select
        //                         {...register("category", {
        //                             required: "Category is required",
        //                         })}
        //                         className="border p-2 rounded-lg  w-full bg-white hover:border-[#838894] outline-none"
        //                     >
        //                         <option value="">Select category</option>
        //                         {allCategories.map((category) => (
        //                             <option
        //                                 key={category._id}
        //                                 value={category.name}
        //                             >
        //                                 {category.name}
        //                             </option>
        //                         ))}
        //                     </select>
        //                     {errors?.category && (
        //                         <span className="text-xs text-[#DA0128]">
        //                             {errors?.category.message}
        //                         </span>
        //                     )}
        //                 </div>
        //                 <Input
        //                     star="true"
        //                     label="Minutes to read"
        //                     placeholder="Enter read minutes"
        //                     name="read"
        //                     type="number"
        //                     errors={errors?.read}
        //                     {...register("read", {
        //                         required: "Minutes to read is required",
        //                     })}
        //                 />
        //                 <Input
        //                     star="true"
        //                     label="Meta Tags"
        //                     placeholder="Enter meta tags sceperated from ,"
        //                     name="metaTags"
        //                     type="text"
        //                     errors={errors?.metaTags}
        //                     {...register("metaTags", {
        //                         required: "Meta tags scaperated from , are required",
        //                     })}
        //                 />
        //                 <Input
        //                     star="true"
        //                     label="Author name"
        //                     placeholder="Enter author name"
        //                     name="createdBy"
        //                     type="text"
        //                     errors={errors?.createdBy}
        //                     {...register("createdBy", {
        //                         required: "Author name is required",
        //                     })}
        //                 />
        //                 <div className="flex gap-2">
        //                     <div className="w-full flex gap-2 items-center">
        //                         <div className="">
        //                             <Input
        //                                 $id={"isFeatured"}
        //                                 name="isFeatured"
        //                                 type="checkbox"
        //                                 divClassName="!m-0"
        //                                 labelClassName="!p-0"
        //                                 {...register("isFeatured")}
        //                             />
        //                         </div>

        //                         <label className="w-fit" htmlFor={"isFeatured"}>
        //                             Featured Page
        //                         </label>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //     </form>
        // </div>
//     );
// };

const PageForm = () => {
    const { register, handleSubmit, formState, setValue, getValues } =
        useForm();
    const { errors } = formState;
    const navigate = useNavigate();
    const { token } = useSelector((state) => state.auth);
    const { loading, allCategories } = useSelector((state) => state.category);
    const [currentPage, setCurrentPage] = useState(null);
    const dispatch = useDispatch();
    const params = useParams();

    // Updated mediaLinks state: initializing as an object with a media array
    const [mediaLinks, setMediaLinks] = useState({ media: [] });

    useEffect(() => {
        const fetchData = async () => {
            await dispatch(getAllCategories());
            if (params.id) {
                const data = await dispatch(getPageByIdOrSlug(params.id));
                setCurrentPage(data);
            }
        };
        if (!currentPage) {
            fetchData();
        }

        if (currentPage) {
            setValue("title", currentPage.title);
            setValue("subtitle", currentPage.subtitle);
            setValue("thumbnail", currentPage.thumbnail);
            setValue("iframe", currentPage.iframe);
            setValue("description", currentPage.description);
            setValue("slug", currentPage.slug);
            setValue("status", currentPage.status);
            setValue("category", currentPage.category);
            setValue("createdBy", currentPage.createdBy);
            setValue("content", currentPage.content);
            setValue("read", currentPage.read);
            setValue("metaTags", currentPage.metaTags);
            setValue("isFeatured", currentPage.isFeatured);

            // Update mediaLinks object with the media array from currentPage
            setMediaLinks({
                media: currentPage.mediaLinks?.media || [],
            });
        }
    }, [params.id, dispatch, currentPage, setValue]);

    const handleCancel = () => {
        navigate(-1);
    };

    const submit = async (data) => {
        // Update mediaLinks field in the form data to include an object with a media array
        data["mediaLinks"] = { media: mediaLinks.media };

        if (params.id) {
            await dispatch(updatePage(currentPage._id, data, navigate, token));
        } else {
            await dispatch(createPage(data, navigate, token));
        }
    };

    const handleEditorChange = (description) => {
        setValue("description", description);
    };

    const deletePageHandler = async () => {
        await dispatch(deletePage(currentPage._id, token));
        navigate(-1);
    };

    const handlePreview = () => {
        navigate("/pages/" + currentPage.slug);
    };

    const handleAddMediaLink = () => {
        setMediaLinks((prevState) => ({
            ...prevState,
            media: [...prevState.media, ""],
        }));
    };

    const handleRemoveMediaLink = (index) => {
        setMediaLinks((prevState) => ({
            ...prevState,
            media: prevState.media.filter((_, i) => i !== index),
        }));
    };

    const handleMediaLinkChange = (index, value) => {
        setMediaLinks((prevState) => ({
            ...prevState,
            media: prevState.media.map((link, i) =>
                i === index ? value : link
            ),
        }));
    };

    return (
        <div className="w-full h-full">
            <form onSubmit={handleSubmit(submit)}>
                {/* top section */}
                <div className="flex justify-between py-6 px-8 gap-2 flex-wrap border-b">
                    <div className="flex items-center gap-2 max-md:ml-7">
                        <h1 className="heading1">
                            {params.id ? "Edit Page" : "Create Page"}
                        </h1>
                    </div>
                    <div className="flex gap-4">
                        {params.id && (
                            <div className="border rounded-md p-2 cursor-pointer group relative">
                                <BsThreeDots className="w-6 h-6" />
                                <div className="border rounded bg-white absolute top-[40px] right-2 invisible group-hover:visible">
                                    <p
                                        className="paragraph border-b py-2 pl-3 pr-4 hover:bg-[#EEF2FF] cursor-pointer"
                                        onClick={handlePreview}
                                    >
                                        Preview
                                    </p>
                                    <p
                                        className="paragraph !text-[#DC2626] py-2 pl-3 pr-4 hover:bg-[#EEF2FF] cursor-pointer"
                                        onClick={deletePageHandler}
                                    >
                                        Delete
                                    </p>
                                </div>
                            </div>
                        )}

                        <Button
                            className="bg-transparent hover:bg-transparent border !text-[#201F37]"
                            onClick={handleCancel}
                        >
                            Cancel
                        </Button>
                        <Button type="submit" loading={loading}>
                            {params.id ? "Update" : "Save"}
                        </Button>
                    </div>
                </div>

                {/* form section */}
                <div className="w-full h-full flex flex-wrap">
                    <div className="w-full md:w-[70%] md:border-r max-md:border-b p-6 flex flex-col gap-4">
                        <Input
                            star="true"
                            label="Title"
                            placeholder="Enter title"
                            name="title"
                            type="text"
                            errors={errors?.title}
                            {...register("title", {
                                required: "Title is required",
                            })}
                        />

                        <Input
                            star="true"
                            label="Sub Title"
                            placeholder="Enter sub title"
                            name="subtitle"
                            type="text"
                            errors={errors?.subtitle}
                            {...register("subtitle", {
                                required: "Sub title is required",
                            })}
                        />

                        <Input
                            star="true"
                            label="Thumbnail URL"
                            placeholder="Enter thumbnail URL"
                            name="thumbnail"
                            type="text"
                            errors={errors?.thumbnail}
                            {...register("thumbnail", {
                                required: "Thumbnail URL is required",
                            })}
                        />

                        <Input
                            star="true"
                            label="Youtube Iframe"
                            placeholder="Enter youtube iframe code"
                            name="iframe"
                            type="text"
                            errors={errors?.iframe}
                            {...register("iframe", {
                                required: "Youtube iframe code is required",
                            })}
                        />

                        <div>
                            <label htmlFor="content" className="paragraph">
                                Body <sup className="text-[#DA0128]">*</sup>
                            </label>
                            <ReactQuill
                                theme="snow"
                                name="description"
                                modules={{ toolbar: toolbarOptions }}
                                value={getValues("description")}
                                className="react-quill"
                                onChange={handleEditorChange}
                            />
                            {errors?.description && (
                                <span className="text-xs text-[#DA0128]">
                                    {errors?.description.message}
                                </span>
                            )}
                        </div>

                        {/* Multiple media links */}
                        <div className="flex flex-col gap-2">
                            <label className="paragraph md:pl-1 paragraph">
                                Media Links
                            </label>
                            {mediaLinks.media.map((link, index) => (
                                <div key={index} className="flex gap-2 mb-2">
                                    <input
                                        type="text"
                                        value={link}
                                        onChange={(e) =>
                                            handleMediaLinkChange(
                                                index,
                                                e.target.value
                                            )
                                        }
                                        className="border p-2 rounded-lg  w-full hover:border-[#838894] outline-none"
                                    />
                                    <Button
                                        className="bg-red-500 hover:bg-red-600 text-white max-w-max"
                                        onClick={() =>
                                            handleRemoveMediaLink(index)
                                        }
                                    >
                                        Remove
                                    </Button>
                                </div>
                            ))}
                            <Button
                                className="bg-green-500 hover:bg-green-600 text-white max-w-max"
                                onClick={handleAddMediaLink}
                            >
                                Add Media Link
                            </Button>
                        </div>
                    </div>
                    <div className="w-full md:w-[30%] flex flex-col gap-3 px-3 pb-6">
                        <div className="py-3.5 px-4 border-b">
                            <h1 className="heading1">Configurations</h1>
                        </div>
                        <Input
                            star="true"
                            label="URL Slug"
                            placeholder="homepage"
                            name="slug"
                            type="text"
                            errors={errors?.slug}
                            {...register("slug", {
                                required: "URL slug is required",
                            })}
                        />
                        <div>
                            <label className="paragraph">
                                Status <sup className="text-[#DA0128]">*</sup>
                            </label>
                            <select
                                {...register("status", {
                                    required: "Status is required",
                                })}
                                className="border p-2 rounded-lg  bg-white w-full hover:border-[#838894] outline-none"
                            >
                                <option value="">Select status</option>
                                <option value="draft">Draft</option>
                                <option value="published">Published</option>
                            </select>   
                            {errors?.status && (
                                <span className="text-xs text-[#DA0128]">
                                    {errors?.status.message}
                                </span>
                            )}
                        </div>
                        <div>
                            <label className="paragraph">
                                Category <sup className="text-[#DA0128]">*</sup>
                            </label>
                            <select
                                {...register("category", {
                                    required: "Category is required",
                                })}
                                className="border p-2 rounded-lg  w-full bg-white hover:border-[#838894] outline-none"
                            >
                                <option value="">Select category</option>
                                {allCategories.map((category) => (
                                    <option
                                        key={category._id}
                                        value={category.name}
                                    >
                                        {category.name}
                                    </option>
                                ))}
                            </select>
                            {errors?.category && (
                                <span className="text-xs text-[#DA0128]">
                                    {errors?.category.message}
                                </span>
                            )}
                        </div>
                        <Input
                            star="true"
                            label="Minutes to read"
                            placeholder="Enter read minutes"
                            name="read"
                            type="number"
                            errors={errors?.read}
                            {...register("read", {
                                required: "Minutes to read is required",
                            })}
                        />
                        <Input
                            star="true"
                            label="Meta Tags"
                            placeholder="Enter meta tags sceperated from ,"
                            name="metaTags"
                            type="text"
                            errors={errors?.metaTags}
                            {...register("metaTags", {
                                required: "Meta tags scaperated from , are required",
                            })}
                        />
                        <Input
                            star="true"
                            label="Author name"
                            placeholder="Enter author name"
                            name="createdBy"
                            type="text"
                            errors={errors?.createdBy}
                            {...register("createdBy", {
                                required: "Author name is required",
                            })}
                        />
                        <div className="flex gap-2">
                            <div className="w-full flex gap-2 items-center">
                                <div className="">
                                    <Input
                                        $id={"isFeatured"}
                                        name="isFeatured"
                                        type="checkbox"
                                        divClassName="!m-0"
                                        labelClassName="!p-0"
                                        {...register("isFeatured")}
                                    />
                                </div>

                                <label className="w-fit" htmlFor={"isFeatured"}>
                                    Featured Page
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};




export default PageForm;
