const BASE_URL = process.env.NODE_ENV === 'production' ? "https://backend.tipconcepts.in": "http://localhost:5000";



export const userEndPoints={
    LOGIN_API: `${BASE_URL}/api/v1/login`,
}


export const pageEndPoints={
    CREATE_PAGE_API: `${BASE_URL}/api/v1/page`,
    UPDATE_PAGE_API: `${BASE_URL}/api/v1/page`,
    DELETE_PAGE_API: `${BASE_URL}/api/v1/page`,

    GET_PAGE_API: `${BASE_URL}/api/v1/page`,
    GET_ALL_PAGES_API: `${BASE_URL}/api/v1/pages`,

}

export const categoryEndPoints={
    CREATE_CATEGORY_API: `${BASE_URL}/api/v1/category`,
    UPDATE_CATEGORY_API: `${BASE_URL}/api/v1/category`,
    DELETE_CATEGORY_API: `${BASE_URL}/api/v1/category`,

    GET_ALL_CATEGORIES_API: `${BASE_URL}/api/v1/categories`,
    GET_CATEGORY_BY_ID_API: `${BASE_URL}/api/v1/category`,
}

export const GET_ALL_CONTACTS_API= `${BASE_URL}/api/v1/contact`;

