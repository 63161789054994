import axios from "axios";
import {
  setLoading,
  setToken,
  setUser,
} from "../../slices/authSlice";
import { userEndPoints } from "../apis";
import toast from "react-hot-toast";

const { LOGIN_API } = userEndPoints;


export const login = (data, navigate, reset) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await axios.post(LOGIN_API, data,{
      withCredentials: true
    });
    if (!response.data.success) {
      throw new Error(response.data.message);
    }
    toast.success("User logged in successfully.");
    localStorage.setItem("user", JSON.stringify(response.data.data));

    dispatch(setUser(response.data.data));
    dispatch(setToken(response.data.token));

    reset();
    navigate("/dashboard");
  } catch (error) {
    toast.error(error.response.data.message);
  } finally {
    dispatch(setLoading(false));
  }
};
