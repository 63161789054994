import React from 'react';
import { Route, Routes, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import PrivateRoute from "./components/core/auth/PrivateRoute";
import OpenRoute from "./components/core/auth/OpenRoute";
import Login from "./pages/Login";
import CreatePage from "./pages/CreatePage";
import DashboardLayout from "./layout/DashboardLayout";
import Dashboard from "./pages/Dashboard";
import Category from "./pages/Category";
import CategoryForm from "./components/core/form/CategoryForm";
import AllPages from "./pages/AllPages";
import Page from "./pages/Page";
import Contact from './pages/Contact';

const App = () => {
    const { token } = useSelector((state) => state.auth);
    return (
        <div className="w-full h-full min-h-screen bg-white text-black">
            <Routes>
                {/* Default route to redirect based on authentication status */}
                <Route
                    path="/"
                    element={
                        token ? <Navigate to="/dashboard" /> : <Navigate to="/login" />
                    }
                />

                {/* Public routes */}
                <Route path="/login" element={<OpenRoute><Login /></OpenRoute>} />

                {/* Private routes */}
                <Route element={<PrivateRoute><DashboardLayout /></PrivateRoute>}>
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/pages/:slug" element={<Page />} />
                    <Route path="/createPage" element={<CreatePage />} />
                    <Route path="/editPage/:id" element={<CreatePage />} />
                    <Route path="/pages" element={<AllPages />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/categories" element={<Category />} />
                    <Route path="/createCategory" element={<CategoryForm />} />
                    <Route path="/editCategory/:id" element={<CategoryForm />} />
                </Route>
            </Routes>
        </div>
    );
};

export default App;
