// slices/contactSlice.js
import { createSlice } from "@reduxjs/toolkit";

const contactSlice = createSlice({
  name: "contact",
  initialState: {
    loading: false,
    contacts: [],
    contactsPagination: {
      currentPage: 1,
      totalPages: 0,
      limit: 15,
    },
  },
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setContacts(state, action) {
      state.contacts = action.payload;
    },
    setContactsPagination(state, action) {
      state.contactsPagination = action.payload;
    },
  },
});

export const { setLoading, setContacts, setContactsPagination } = contactSlice.actions;

export default contactSlice.reducer;
